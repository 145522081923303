import React from 'react';
import Layout from '../components/layout';
import Qualities from '../components/qualities';
import Svg from '../components/svg';
import SEO from '../components/seo';
import A80 from '../animations/a80';
import A160 from '../animations/a160';
import A400 from '../animations/a400';
import MilkLink from '../components/milk-link';

export default ({ pageContext: { content, pages, lang, texts, langs } }) => {
  const data = content.data;
  return (
    <Layout pages={pages} lang={lang} texts={texts} langs={langs}>
      <SEO
        description={data.seo_description}
        title={data.seo_title}
        image={data.seo_image}
      />
      <div className='layer-top layer-top-services'>
        <A160 className='services-liquid-1' />
        <A400 className='services-liquid-2' />
        <div className='relative'>
          <div className='wrap-full pl-xl pr-l'>
            <div className='group group-services'>
              <div
                className='left'
                data-sal='slide-right'
                data-sal-duration='1000'
              >
                <h1 dangerouslySetInnerHTML={{ __html: data.intro_title }} />
                <MilkLink
                  className='btn mobile-hide'
                  to={`/${lang}${data.intro_btn_url}`}
                >
                  {data.intro_btn}
                </MilkLink>
              </div>
              <div className='right'>
                <div
                  data-sal='slide-left'
                  data-sal-duration='1000'
                  className='h3'
                  dangerouslySetInnerHTML={{ __html: data.intro_text }}
                />
                <MilkLink
                  className='btn desktop-hide'
                  to={`/${lang}${data.into_btn_url}`}
                >
                  {data.intro_btn}
                </MilkLink>
              </div>
            </div>
          </div>
          <div className='wrap-full pl-l pr-l services-image'>
            <div data-sal='slide-up' data-sal-duration='1000'>
              <img
                src={data.intro_image}
                alt={data.intro_title}
                title={data.intro_title}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='layer layer-important'>
        <div className='wrap-full pl-xl pr-xl'>
          <h2
            data-sal='slide-left'
            data-sal-duration='1000'
            dangerouslySetInnerHTML={{ __html: data.important_title }}
          />
        </div>
        <div className='important-bottom'>
          <div className='wrap-full'>
            <div>
              <div data-sal='slide-left' data-sal-duration='800' className='no'>
                1<Svg name='circle' />
              </div>
              <h3
                className='h3'
                data-sal-delay='200'
                data-sal='slide-up'
                data-sal-duration='800'
                dangerouslySetInnerHTML={{ __html: data.important_1_title }}
              />
              <div
                data-sal-delay='200'
                data-sal='slide-up'
                data-sal-duration='800'
                dangerouslySetInnerHTML={{ __html: data.important_1_text }}
              />
            </div>
            <div>
              <div
                data-sal-delay='400'
                data-sal='slide-left'
                data-sal-duration='800'
                className='no'
              >
                2<Svg name='circle' />
              </div>
              <h3
                className='h3'
                data-sal-delay='600'
                data-sal='slide-up'
                data-sal-duration='800'
                dangerouslySetInnerHTML={{ __html: data.important_2_title }}
              />
              <div
                data-sal-delay='600'
                data-sal='slide-up'
                data-sal-duration='800'
                dangerouslySetInnerHTML={{ __html: data.important_2_text }}
              />
            </div>
            <div>
              <div
                data-sal-delay='800'
                data-sal='slide-left'
                data-sal-duration='800'
                className='no'
              >
                3<Svg name='circle' />
              </div>
              <h3
                className='h3'
                data-sal-delay='1000'
                data-sal='slide-up'
                data-sal-duration='800'
                dangerouslySetInnerHTML={{ __html: data.important_3_title }}
              />
              <div
                data-sal-delay='1000'
                data-sal='slide-up'
                data-sal-duration='800'
                dangerouslySetInnerHTML={{ __html: data.important_3_text }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='wrap-full pl-xl pr-xxl relative'>
        <A400 className='services-liquid-3' />
        <div className='group group-qualities'>
          <div className='left'>
            <h3
              className='h3'
              data-sal='slide-right'
              data-sal-duration='1000'
              dangerouslySetInnerHTML={{ __html: data.qualities_title }}
            />
          </div>
          <div className='right' data-sal='slide-left' data-sal-duration='1000'>
            <ul>
              {data.qualities.map((item) => (
                <li className='quality' key={item.quality}>
                  <span>{item.quality}</span>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.quality_about }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};
